import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";

import LandingPagesIndex from "../landing/ui/pages/index";
import LandingPagesInstallNew from "../landing/ui/pages/install-v2";


const AppRouter = () => {
    return (
        <Router>
            <Switch>
                <Route exact path="/" component={LandingPagesIndex} />
                <Route path="/install">
                  <LandingPagesInstallNew />
                </Route>
            </Switch>
        </Router>
    )
};
export default AppRouter;
