import Icon from 'icons';
import React from 'react';

const policyLink = `${process.env.PUBLIC_URL}/docs/policy.html`;
const agreementLink = `${process.env.PUBLIC_URL}/docs/agreement.html`;
const ofertaLink = `${process.env.PUBLIC_URL}/docs/oferta.html`;

const LandingFooter = () => {
  return (
    <div className="footer">
      <div className="container">
        <div className="row">
          <div className="col-lg-3">
            <a href={agreementLink} target="_blank" className="footer-link">Лицензионное соглашение</a><br />
            <a href={policyLink} target="_blank" className="footer-link">Политика конфиденциальности</a><br />
            <a href={ofertaLink} target="_blank" className="footer-link">Оферта</a>
          </div>
          <div className="col-lg-3 col-6 mt-2 mt-lg-0">
            <a href="tel:+79175961742" className="footer-link">+7 917 596-17-42</a><br />
            <a href="mailto:support@progresto.app" className="footer-link">support@progresto.app</a>
          </div>
          <div className="col-lg-3 col-6 mt-2 mt-lg-0 text-right">
            {/* <a href="https://www.facebook.com/ProGres-App-105567671512908"
              target="_blank"
              className="social-link">
              <Icon name="facebook" />
            </a>
            <a href="https://instagram.com/progres.app"
              style={{ marginLeft: '20px' }}
              target="_blank"
              className="social-link">
              <Icon name="instagram" />
  </a> */}
        </div>
        <div className="col-lg-3 text-left text-md-right mt-2 mt-lg-0">
          <div className="footer-text">Байкал СТ, ООО</div>
          {/*<div className="footer-text"> ©2023, ИП Селиванова А. С.</div>*/}
          {/*<div className="footer-text">ИНН: 502713926994</div>*/}
          <div className="footer-text">©2023</div>
        </div>
      </div>
    </div>
    </div >
  )
};

export default LandingFooter;
