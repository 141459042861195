import Icon from 'icons';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const links = [
  { title: 'Начало', href: '/#section-0' },
  { title: 'Возможности', href: '/#section-1' },
  { title: 'Как работает', href: '/#section-q' },
  { title: 'Обратная связь', href: '/#section-cb' },
  { title: 'Помощь', href: '/install', scroll: false },
];

type Props = {
  onTryClicked: () => void
}
const LandingLinksHeader = (props: Props) => {
  const [isMobileMenuVisible, setMobileMenuVisibility] = useState(false);

  const onMenuClicked = () => {
    if (isMobileMenuVisible) {
      document.body.style.overflowY = "";
    } else {
      document.body.style.overflowY = "hidden";
    }
    setMobileMenuVisibility(!isMobileMenuVisible);
  };

  return (
    <>
      <header className="header-desktop">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-2">
              <Link to="/">
                <Icon name="logoLabel" />
              </Link>
            </div>
            <div className="col-lg-8">
              <div
                className="nav">
                {links.map((l) => {
                  return (
                    <div className="nav-it" key={l.href}>
                      <Link className="nav-link" to={l.href}>{l.title}</Link>
                    </div>
                  )
                })}
              </div>
            </div>
           {/* <div className="col-lg-2 d-flex justify-content-end">
              <button onClick={props.onTryClicked} type="button" className="btn">Попробовать</button>
              </div> */}
          </div>
        </div>
      </header>
      <header className="header-mobile">
        <div className="header-mobile__header container">
          <div className="row align-items-center justify-space-between">
            <div className="col">
              <div className="d-flex align-items-center"><Icon name="logoLabel" /></div>
            </div>
            <div className="col d-flex justify-content-end">
              <div onClick={onMenuClicked} className="d-flex align-items-center">
                <Icon name={isMobileMenuVisible ? "menuClose" : "menuOpen"} />
                <span className="mobile-menu-btn">Меню</span>
              </div>
            </div>
          </div>
        </div>
        {isMobileMenuVisible && (
          <div
            className="mobile-nav text-center">
            <>
              {links.map((l) => {
                return (
                  <div className="mnav-it" key={l.href}>
                    <Link className="nav-link" to={l.href}>{l.title}</Link>
                  </div>
                )
              })}
             {/* <button onClick={props.onTryClicked} type="button" className="btn">Попробовать</button> */}
            </>

          </div>
        )}
      </header>
    </>
  )
};

export default LandingLinksHeader;
