import { FetchResource } from "@snap-alex/domain-js";
import { toast } from 'react-toastify';
const fetch = window.fetch.bind(window);

const httpResource = new FetchResource('https://progresto.app/api/v1', {
  handleError: (payload) => {
    let errors = payload?.parsedBody?.errors as string[];
    let messages;
    if (errors == null || errors?.length === 0) {
      const { status, url, statusText } = payload.response;
      messages = [`${status}. ${statusText}. ${url}. `];
    } else if (errors != null && errors?.length > 0) {
      messages = errors;
    }
    // hotfix
    messages = messages.map((msg) => {
      switch (msg) {
        case 'user already exists':
          return 'Пользователь уже зарегистрирован';
        default:
          return msg;
      }
    })
    const toastMessage = createMessagesCmp(messages);
    toast(toastMessage, { type: toast.TYPE.ERROR });
  },
  timeOffset: false,
}, fetch);
export default httpResource;
