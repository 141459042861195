import styled from 'styled-components';

export const Container = styled.div`
  background-color: #fff;
  padding-top: 100px;
`;

type H2Props = {
  noTopMargin?: boolean
}
export const H2 = styled.h2<H2Props>`
  color: #25224A;
  font-family: 'Rubik', sans-serif;
  font-size: 48px;
  font-weight: 700;
  line-height: 58px;
  margin-bottom: 44px;
  ${(props) => props.noTopMargin ? '' : 'margin-top: 100px;'}
`;

export const H2New = styled.h2<H2Props>`
  color: #25224A;
  font-family: 'Rubik', sans-serif;
  font-size: 48px;
  font-weight: 700;
  line-height: 58px;
  margin-bottom: 0px;
  ${(props) => props.noTopMargin ? '' : 'margin-top: 100px;'}
`;

type H3Props = {
  noTopMargin?: boolean
}
export const H3 = styled.h2<H3Props>`
  color: #25224A;
  font-family: 'Rubik', sans-serif;
  font-size: 32px;
  line-height: 144%;
  font-weight: bold;
  margin-bottom: 24px;
  ${(props) => props.noTopMargin ? '' : 'margin-top: 56px;'}
  position: relative;
`;

export const H3IconWrap = styled.div`
  position: absolute;
  left: -40px;
`;

export const P1 = styled.p`
  color: #25224A;
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  margin: 0;
  padding: 0;
`;

export const InfoCard = styled.div`
  position: relative;
  background: #F6F7FA;
  border-radius: 12px;
  padding: 20px 24px 20px 64px;
`;

export const InfoCardIcon = styled.div`
  position: absolute;
  width: 24px;
  height: 24px;
  left: 24px;
  top: 24px;
`;

type TextRowProps = {
  noSpacing?: boolean
};
export const TextRow = styled.div<TextRowProps>`
  ${(props) => props.noSpacing ? '' : 'margin-bottom: 36px;'}
  &:last-child {
    margin-bottom: 0;
  }
`;

export const BlueText = styled.span`
  color: #3E50F7;
`;

export const BoldText = styled.span`
  font-weight: 700;
`;


export const CheckTitle = styled.div`
  font-family: 'Nunito', sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 35px;
  margin-bottom: 22px;
`;

export const CheckTitleLeft = styled.span`
  color: #3E50F7;
`;
export const CheckTitleRight = styled.span`
  color: #00D463;
`;

export const FixedSidebar = styled.div`
  position: fixed;
  @media (max-width: 992px) {
    position: relative;
  }
`;

export const FixedSidebarContent = styled.div`
  max-width: 270px;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
`

export const Space = styled.div`
  width: 1rem;
  height: 1rem;
`
