import { scrollToHref } from 'app/modules/ud-ui/scroll-to-href';
import Icon from 'icons';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Scrollspy from 'react-scrollspy';

const links = [
  { title: 'Начало', href: '#section-0', scroll: true },
  { title: 'Возможности', href: '#section-1', scroll: true },
  { title: 'Как работает', href: '#section-q', scroll: true },
  { title: 'Обратная связь', href: '#section-cb', scroll: true },
  { title: 'Помощь', href: '/install', scroll: false },
];

const scrollSpyItems = ['section-0', 'section-1', 'section-q', 'section-cb'];

type Props = {
  onTryClicked: () => void
}
const LandingHeader = (props: Props) => {
  const [isMobileMenuVisible, setMobileMenuVisibility] = useState(false);

  const onMenuClicked = () => {
    if (isMobileMenuVisible) {
      document.body.style.overflowY = "";
    } else {
      document.body.style.overflowY = "hidden";
    }
    setMobileMenuVisibility(!isMobileMenuVisible);
  };

  const onDesktopLinkClicked = (e: any, link: any) => {
    e.preventDefault();
    e.stopPropagation();
    scrollToHref(link.href);
  };

  const onMobileLinkClicked = (e: any, link: any) => {
    e.preventDefault();
    e.stopPropagation();
    scrollToHref(link.href, 'instant');
    document.body.style.overflowY = "";
    setMobileMenuVisibility(false);
  };

  const onMobileNavLinkClicked = () => {
    document.body.style.overflowY = "";
  };

  return (
    <>
      <header className="header-desktop">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-2">
              <Icon name="logoLabel" />
            </div>
            <div className="col-lg-8">
              <Scrollspy
                offset={110}
                className="nav"
                componentTag="div"
                items={scrollSpyItems}
                currentClassName="active">
                {links.map((l) => {
                  if (l.scroll) {
                    return (
                      <div className="nav-it" key={l.href}>
                        <a href={l.href}
                          onClick={(e) => onDesktopLinkClicked(e, l)}
                          className="nav-link">{l.title}</a>
                      </div>
                    )
                  }
                  return (
                    <div className="nav-it" key={l.href}>
                      <Link className="nav-link" to={l.href}>{l.title}</Link>
                    </div>
                  )
                })}
              </Scrollspy>
            </div>

            <div className="col-lg-2 d-flex justify-content-end">
              <button onClick={props.onTryClicked} type="button" className="btn">Web-клиент</button>
            </div>

          </div>
        </div>
      </header>
      <header className="header-mobile">
        <div className="header-mobile__header container">
          <div className="row align-items-center justify-space-between">
            <div className="col">
              <div className="d-flex align-items-center"><Icon name="logoLabel" /></div>
            </div>
            <div className="col d-flex justify-content-end">
              <div onClick={onMenuClicked} className="d-flex align-items-center">
                <Icon name={isMobileMenuVisible ? "menuClose" : "menuOpen"} />
                <span className="mobile-menu-btn">Меню</span>
              </div>
            </div>
          </div>
        </div>
        {isMobileMenuVisible && (
          <Scrollspy
            offset={110}
            className="mobile-nav text-center"
            componentTag="div"
            items={scrollSpyItems}
            currentClassName="active">
            {links.map((l) => {
              if (l.scroll) {
                return (
                  <div className="mnav-it" key={l.href}>
                    <a href={l.href}
                      onClick={(e) => onMobileLinkClicked(e, l)}
                      className="nav-link">{l.title}</a>
                  </div>
                )
              }
              return (
                <div className="mnav-it" key={l.href}>
                  <Link onClick={onMobileNavLinkClicked} className="nav-link" to={l.href}>{l.title}</Link>
                </div>
              )
            })}
          <button onClick={props.onTryClicked} type="button" className="btn">Web-клиент</button>
          </Scrollspy>
        )}
      </header>
    </>
  )
};

export default LandingHeader;
