import { createSelector } from "@reduxjs/toolkit";
import { landingStateSelector } from "../selectors";

export const landingFeedbackStateSelector = createSelector(
  landingStateSelector,
  (state) => state.feedback
);

export const isLoadingSelector = createSelector(
  landingFeedbackStateSelector,
  (state) => state.isLoading
)