import styled from "styled-components";


export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const Icon = styled.div`
  width: 14px;
  height: 14px;
`;

export const StyledCheckbox = styled.span`
    position: relative;
    top: 5px;
    display: inline-block;
    width: 16px;
    height: 16px;
    /* margin: 0; */
    background-color: ${(props: { checked: boolean }) => props.checked ? '#25224A' : 'white'};
    border: 1px solid #25224A;
    border-radius: 2px;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
    /* ${HiddenCheckbox}:focus + & {
        box-shadow: 0px 0px 8px 1px rgba(0,85,255,0.55);
    }; */
    ${Icon} {
        visibility: ${(props: { checked: boolean }) => props.checked ? 'visible' : 'hidden'}
    }
`;

export const LabelText = styled.span`
  cursor: pointer;
  color: #25224A;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  line-height: 164%;
`;
