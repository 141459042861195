import Icon from 'icons';
import React, { useCallback } from 'react';
import Accordion from '../../components/accordion';
import LandingFooter from '../../components/footer';
import LandingHeader from '../../components/header';
import LandingSection from '../../components/section';

import * as S from "./styles";
import { useDispatch } from 'react-redux';
import LandingFeedbackReduxForm from '../../components/feedback-form';
import { sendFeedback } from 'app/modules/landing/store/feedback/actions';

const sections = require('./content/sections.json');
const questions = require('./content/questions.json');

const LandingPagesIndex = () => {
  const dispatch = useDispatch();

  const onTryClicked = () => {
    window.open('https://client.progresto.app/')
  }
  
  const onFeedbackSubmit = useCallback((info: any) => {
    dispatch(sendFeedback(info));
  }, []);

  return (
    <S.Container>
      <LandingHeader onTryClicked={onTryClicked} />

      <section id="section-0" className="section section-promo">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <p className="promo-hint"><span className="text-blue">Чтобы ремонт</span> <span className="text-green">шел по плану</span></p>
              <h1 className="promo-title">Приложение для всех, кто делает ремонт</h1>
              <h3 className="promo-subtitle text-1">ProGresto помогает управлять процессом ремонта и вести финансовую отчетность.</h3>


              <div className="promo-actions">
                <a href="https://apps.apple.com/us/app/progres/id1534485742" target="_blank" id="ios-link" className="promo-link">
                  <Icon name="ios" />
                  <span className="promo-link-text">App Store</span>
                </a>
                <a href="https://play.google.com/store/apps/details?id=llc.mis.progres" target="_blank" id="android-link"
                  className="promo-link promo-link--google">
                  <Icon name="android" />
                  <span className="promo-link-text">Google Play</span>
                </a>
              </div>

              <div className="promo-actions">
                <a href="https://client.progresto.app" target="_blank" id="web-client-link" 
                  className="promo-link-web-client">
                  <span className="promo-link-text">Web-версия для ПК</span>                  
                </a>
              </div>
            </div>
            <div className="col-md-6 promo-media">
              <div className="promo-bg"></div>
              <div>
                <Icon maxWidth="375px" maxHeight="100%" name="iphone" className="promo-img" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="promo-divider">
        <div className="promo-divider-it">
          🔨
        </div>
      </div>

      <p className="promo-description"><span className="font-weight-400">Будь вы заказчик, дизайнер или строитель, ремонт любого объекта может идти по плану и не изматывать ни финансово, ни эмоционально. Принимайте взвешенные решения, экономьте время и ресурсы, облегчите себе работу — с новым приложением для ремонта</span> <span className="font-weight-700">ProGresto.</span></p>

      { sections.map((section: any) => {
        return <LandingSection key={section.id} section={section} />
      })}

      <div id="section-q" className="text-2 text-center">Как это работает</div>

      <div className="container">
        <div className="row">
          <div className="col">
            {questions.map((it: any) => {
              return (<Accordion key={it.id} title={it.title} text={it.text} />);
            })}
          </div>
        </div>
      </div>


      <div id="section-cb" className="text-2 text-center" style={{ marginTop: '164px' }}>Обратная связь</div>
      <div className="text-1 text-center" style={{ marginBottom: '44px' }}>Присылайте ваши вопросы и пожелания.</div>

      <LandingFeedbackReduxForm onSubmit={onFeedbackSubmit} />
      <LandingFooter />

    </S.Container>
  );
}

export default LandingPagesIndex;
