export const required = (value: string) => {
    if (value) return undefined

    return "Обязательное поле"
}

export const requiredNumber = (value: any) => {
    if (!isNaN(value)) return undefined

    return "Допустимы только числа"
}
export const requiredCreator = (errorMessage: string) => (value: string) => {
    if (value) return undefined

    return `${errorMessage}`
}

export const maxLengthCreator = (maxLength: number) => (value: string) => {
    if (value?.length > maxLength) return `Максимальная длина - ${maxLength}`

    return undefined
}

export const minLengthCreator = (minLength: number) => (value: string) => {
    if (value?.length < minLength) return `Минимальная длина - ${minLength}`

    return undefined
}

export const email = (value: string) =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ?
        'Некорректный email' : undefined

export const mobilePhone = (value: string) =>
    value && !/^(\+7|7|8)?[\s\-]?\(?[489][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/i.test(value) ?
        'Некорректный телефон' : undefined

export const time = (value: string) =>
    value && !/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/i.test(value) ?
        'Некорректное время' : undefined

export const commaEnumeration = (value: string) =>
    value && !/^([\w,\s*]+)$/i.test(value) ?
        'Некорректный формат' : undefined

export const commaEnumerationNumber = (value: string) =>
    value && !/^([\d,\s*]+)$/i.test(value) ?
        'Некорректный формат' : undefined