import styled from 'styled-components';

export const StyledTextarea = styled.textarea`
    outline: none;
    resize: none;
    border: 1px solid #CECFDA;
    border-radius: 4px;
    background-color: white;
    width: inherit;
    padding: 8px 12px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    width: 100%;
`;
