import Icon from 'icons';
import React, { useCallback } from 'react';
import LandingFooter from '../../components/footer';

import * as S from "./styles";
import { useDispatch } from 'react-redux';
import LandingFeedbackReduxForm from '../../components/feedback-form';
import { sendFeedback } from 'app/modules/landing/store/feedback/actions';
import LandingComponentsInstallReduxForm from '../../components/install-form';
import LandingComponentsVideo from '../../components/video';

const InstallIos = () => {
  const dispatch = useDispatch();
  
  const onFeedbackSubmit = useCallback((info: any) => {
    dispatch(sendFeedback(info));
  }, []);
  
  return (
    <S.Container>
      <div className="container">
        <div className="row">
          <div className="col-lg-3">
            <S.FixedSidebar>
              <S.FixedSidebarContent>
                <S.CheckTitle>
                  <S.CheckTitleLeft>Чек-</S.CheckTitleLeft>
                  <S.CheckTitleLeft>лист</S.CheckTitleLeft>
                </S.CheckTitle>
                <LandingComponentsInstallReduxForm />
              </S.FixedSidebarContent>
            </S.FixedSidebar>
          </div>
          <div className="col-lg-9">

            <S.H3 noTopMargin id="install_reg" className="mt-4 mt-lg-0">Регистрация аккаунта в приложении ProGresto</S.H3>

            <S.TextRow>
              <LandingComponentsVideo url='https://s3video.ams3.digitaloceanspaces.com/landing/install_ios/reg.mp4'/>
            </S.TextRow>

            <S.TextRow>
              <S.P1>
                Выполните следующие действия:
              </S.P1>
            </S.TextRow>

            <S.TextRow>
            <ul className="l-ul">
                <li>Запустите приложение.</li>
                <li>Нажмите кнопку <S.BoldText>"Регистрация"</S.BoldText>.</li>
                <li>Заполните необходимые поля для регистрации, указав свои данные. Выберете из предложенных вариантов свою роль.</li>
                <li>Введите код из смс, который был отправлен на указанный номер для подтверждения регистрации.</li>
              </ul>
              </S.TextRow>
           

            <S.TextRow>
              <S.InfoCard>
                <S.InfoCardIcon><Icon name="statusDanger" /></S.InfoCardIcon>
                <S.P1>Важно! Для некоторых мобильных операторов, подтверждение номера осуществляется при помощи входящего звонка. Сбросьте этот вызов и в качестве кода, введите последние 4 цифры данного номера телефона.  </S.P1>
              </S.InfoCard>
            </S.TextRow>


            <S.H2 id="install_project">Создание проекта в приложении ProGresto</S.H2>

            <S.TextRow>
              <LandingComponentsVideo url='https://s3video.ams3.digitaloceanspaces.com/landing/install_ios/project.mp4'/>
            </S.TextRow>

            <S.TextRow>
              <S.P1>
                Чтобы начать работу над вашим ремонтом, нужно создать проект.
              </S.P1>
            </S.TextRow>

          <S.TextRow>
           <ul className="l-ul">
                <li>Нажмите кнопку <S.BoldText>«+»</S.BoldText> для добавления первого проекта.</li>
                <li>Выберите тип объекта, заполните поля <S.BoldText>«Название проекта»</S.BoldText>, <S.BoldText>«Адрес»</S.BoldText>, <S.BoldText>«Площадь»</S.BoldText> и нажмите кнопку <S.BoldText>«Сохранить»</S.BoldText>.</li>
                <li> Пригласите людей в ваш проект, нажав кнопку <S.BoldText>«Добавить приглашение»</S.BoldText>. Приложение позволяет работать над проектом сразу всем участникам ремонта — клиент, строители,  дизайнеры.  На экране <S.BoldText>«Добавить приглашение»</S.BoldText> выберите роль участника, укажите его номер телефона и нажмите кнопку <S.BoldText>«Пригласить»</S.BoldText>. Если других участников команды пока нет, то нажмите кнопку <S.BoldText>«Сделаю позже»</S.BoldText>.</li>
                <S.TextRow>
            <S.InfoCard>
              <S.InfoCardIcon><Icon name="statusDanger" /></S.InfoCardIcon>
                <S.P1>Важно! Чтобы человек мог добавиться в ваш проект, у него должен быть зарегистрированный аккаунт в приложении ProGresto.</S.P1>
              </S.InfoCard>
            </S.TextRow>
                <li>Утвердите план работ. Для экономии времени вы можете воспользоваться готовым списком типовых задач для ремонта. Если вам не понадобится типовой список в полном объёме, отключите эту функцию и вручную добавьте  необходимые задачи, которые вы можете найти в категориях <S.BoldText>«Дизайн»</S.BoldText>, <S.BoldText>«Ремонт»</S.BoldText> и <S.BoldText>«Магазин»</S.BoldText>. При необходимости дополните готовый список своими пунктами при помощи кнопки <S.BoldText>«Создать новую задачу»</S.BoldText> внутри каждой из категорий.</li>
                <li>Для начала работы над проектом, нажмите кнопку <S.BoldText>«Утвердить»</S.BoldText>.</li>
                <S.TextRow>
            <S.InfoCard>
              <S.InfoCardIcon><Icon name="statusDanger" /></S.InfoCardIcon>
                <S.P1>Важно! Утвердить проект может создатель проекта и участник с ролью «Управляющий»</S.P1>
              </S.InfoCard>
            </S.TextRow>
           </ul>
          </S.TextRow>


            <S.H2 id="install_overview">Обзор основных экранов</S.H2>

            <S.TextRow>
              <LandingComponentsVideo url='https://s3video.ams3.digitaloceanspaces.com/landing/install_ios/review.mp4'/>
            </S.TextRow>

            <S.TextRow>
              <S.P1>
                Цель данного шага — познакомиться с основными экранами и возможностями данного приложения.
              </S.P1>
            </S.TextRow>

            <S.TextRow>
              <S.P1>
                 В нижней панели расположены 3 кнопки:
              </S.P1>
            </S.TextRow>

            <S.TextRow>
              <ul className="l-ul">
                <li><S.BoldText>"Проекты"</S.BoldText> - здесь отображается количество созданных вами проектов.</li>
                <li><S.BoldText>"Профиль"</S.BoldText> отвечает за настройки аккаунта (телефон, email, пароль).</li>
                <li><S.BoldText>"Инфо"</S.BoldText> хранит всю справочную информацию и связь с поддержкой.</li>
              </ul>
            </S.TextRow>

            <S.TextRow>
              <S.P1>
                Нажмите на свой проект.
            </S.P1>
            </S.TextRow>

              <S.H3>Задачи</S.H3>

            <S.TextRow>
              <S.P1>
                Нажмите <S.BoldText>«Задачи»</S.BoldText>  в верхней панели. <S.BoldText>«Задачи»</S.BoldText> — экран, который хранит в себе весь список задач, из которых состоит ваш ремонт. Для удобства этот список разделён на три категории: дизайн, ремонт и магазин. Также эти категории можно редактировать и создавать новые.
              </S.P1>
            </S.TextRow>

            <S.TextRow>
              <S.P1>
                 У каждой задачи есть 5 возможных статусов:
              </S.P1>
            </S.TextRow> 

            <S.TextRow>
              <ul className="l-ul">
                <li><S.BoldText>"Новая"</S.BoldText> - ответственный и дата выполнения еще не назначены.</li>
                <li><S.BoldText>"В работе"</S.BoldText> - работа началась, ответственный и дата выполнения назначены.</li>
                <li><S.BoldText>"На проверке"</S.BoldText> - работа выполнена и отправлена на согласование.</li>
                <li><S.BoldText>"На доработке"</S.BoldText> - владелец проекта не согласовал задачу и вернул на доработку.</li>
                <li><S.BoldText>"Принята"</S.BoldText> - владелец проекта принял работу.</li>
              </ul>
            </S.TextRow>

            <S.TextRow>
              <S.P1>
                Нажмите на одну из категорий и просмотрите весь список предложенных задач.
              </S.P1>
            </S.TextRow>


            <S.H3>О Проекте</S.H3>

            <S.TextRow>
              <S.P1>
                Нажмите <S.BoldText>"О проекте"</S.BoldText>  в верхней панели. <S.BoldText>"О проекте"</S.BoldText> — главный  информационный экран приложения, где вы сможете следить за всеми действиями, прогрессом и бюджетом текущего ремонта. 
              </S.P1>
            </S.TextRow>


            <S.TextRow>
              <S.P1>
                Кнопка <S.BoldText>"Журнал событий"</S.BoldText> позволяет просматривать все изменения вашего проекта в хронологическом порядке.
              </S.P1>
            </S.TextRow>

            <S.TextRow>
              <S.P1>
                Шкала <S.BoldText>"Бюджет"</S.BoldText> позволяет контролировать уровень расходов. 
              </S.P1>
            </S.TextRow>

            <S.TextRow>
              <S.P1>
               Шкала <S.BoldText>"Прогресс выполнения"</S.BoldText> отображает сроки выполнения ремонта и показывает уровень выполненных работ.
              </S.P1>
            </S.TextRow>

            <S.TextRow>
              <S.P1>
                Шкала <S.BoldText>"Задачи"</S.BoldText> показывает статус всех решенных, нерешенных и требующих доработки задач.
              </S.P1>
            </S.TextRow>

            <S.TextRow>
              <S.P1>
               На экране <S.BoldText>"Команда"</S.BoldText> можно добавлять и удалять участников.
              </S.P1>
            </S.TextRow>

            <S.TextRow>
              <S.P1>
                На экране <S.BoldText>"Объект"</S.BoldText> можно изменять параметры проекта.
              </S.P1>
            </S.TextRow>


            <S.H3>Затраты</S.H3>

            <S.TextRow>
              <S.P1>
                Нажмите <S.BoldText>"Затраты"</S.BoldText>  в верхней панели. Это калькулятор, который контролирует и подробно расписывает бюджет вашего ремонта.
              </S.P1>
            </S.TextRow>

            <S.H3>Документы</S.H3>

            <S.TextRow>
              <S.P1>
                Нажмите <S.BoldText>"Документы"</S.BoldText> в верхней панели. Экран служит отдельной папкой для всей документации, фото и других важных материалов, касающихся вашего ремонта.
              </S.P1>
            </S.TextRow>

<S.H3>Как пригласить людей в проект?</S.H3>

            <S.TextRow>
              <LandingComponentsVideo url='https://s3video.ams3.digitaloceanspaces.com/landing/install_ios/team.mp4'/>
            </S.TextRow>

            <S.TextRow>
              <ul className="l-ul">
                <li>Выберите экран <S.BoldText>«О Проекте».</S.BoldText></li>
                <li>Нажмите кнопку <S.BoldText>«Команда»</S.BoldText>.</li>
                <li> Нажмите кнопку <S.BoldText>«Пригласить»</S.BoldText>.</li>
                <li> Выберите <S.BoldText>«Добавить приглашение»</S.BoldText>.</li>
                <li>На экране <S.BoldText>«Добавить приглашение»</S.BoldText> выберите роль участника — управляющий, строитель или дизайнер — и укажите его телефон.</li>
                <li>Нажмите кнопку <S.BoldText>«Добавить»</S.BoldText>.</li>
                <li>Если указанный участник команды уже зарегистрировался, то его имя должно появиться в списке участников команды.</li>
              </ul>
            </S.TextRow>
        
            <S.TextRow>
              <S.InfoCard>
                <S.InfoCardIcon><Icon name="statusDanger" /></S.InfoCardIcon>
                <S.P1>Важно! "Управляющему" проекта доступны все задачи. Исполнителям — дизайнерам, строителям — будут доступны только те задачи, которые им назначили или они сами создали.</S.P1>
              </S.InfoCard>
            </S.TextRow>

            <S.H3>Как изменить характеристики проекта?</S.H3>

            <S.TextRow>
              <LandingComponentsVideo url='https://s3video.ams3.digitaloceanspaces.com/landing/install_ios/settings.mp4'/>
            </S.TextRow>

            <S.TextRow>
              <ul className="l-ul">
                <li>Нажмите на кнопку <S.BoldText>«Объект»</S.BoldText>.</li>
                <li>Если вы владелец проекта, вам будет доступна кнопка <S.BoldText>«Изменить»</S.BoldText>. Нажмите её.</li>
                <li>В открывшемся экране нажмите на кнопку <S.BoldText>«Дополнительные поля»</S.BoldText>.</li>
                <li>Заполните одно из предложенных полей.</li>
                <li>Нажмите кнопку <S.BoldText>«Сохранить»</S.BoldText>.</li>
                <li>Проверьте, что изменения которые вы внесли, появились на экране <S.BoldText>«Параметры проекта»</S.BoldText>.</li>            
              </ul>
            </S.TextRow>

            <S.H2 id="install_tasks">Работа с задачами в приложении ProGresto</S.H2>

            <S.TextRow>
              <S.P1>
                Ваш ремонт в приложении структурирован в виде списка задач — работ, которые необходимо выполнить. Все задачи разделены на три категории — дизайн, ремонт и магазин.
              </S.P1>
            </S.TextRow>

            <S.TextRow>
              <ul className="l-ul">
                <li>В категории <S.BoldText>«Дизайн»</S.BoldText> обсуждаются вопросы, которые связаны с дизайном.</li>
                <li>В категории <S.BoldText>«Ремонт»</S.BoldText> фиксируется процесс строительных работ.</li>
                <li>В категории <S.BoldText>«Магазин»</S.BoldText> хранится список задач и покупок, которые нужно сделать для ремонта.</li>
              </ul>
            </S.TextRow>

            <S.TextRow>
              <S.P1>
                Экран <S.BoldText>«Задачи»</S.BoldText> состоит из 3х основных пунктов: <S.BoldText>«Заметки»</S.BoldText>, <S.BoldText>«Документы»</S.BoldText>, <S.BoldText>«Затраты»</S.BoldText>.
              </S.P1>
            </S.TextRow>

            <S.H3>Как ставить задачу и назначать ответственных?</S.H3>

            <S.TextRow>
              <LandingComponentsVideo url='https://s3video.ams3.digitaloceanspaces.com/landing/install_ios/work.mp4'/>
            </S.TextRow>

            <S.TextRow>
              <S.P1>
                После создания проекта все задачи находятся в статусе <S.BoldText>«Новая»</S.BoldText>. Чтобы начать работу над задачей, ей нужно назначить ответственного и перевести в статус <S.BoldText>«В работе»</S.BoldText>.
              </S.P1>
            </S.TextRow>

            <S.TextRow>
              <S.P1>
                Создание новой задачи:
              </S.P1>
            </S.TextRow>

            <S.TextRow>
              <ul className="l-ul">
                <li>На экране задач нажмите <S.BoldText>«+»</S.BoldText>.</li>
                <li>На экране задачи выберите <S.BoldText>Этап</S.BoldText>, <S.BoldText>Ответственного</S.BoldText> и <S.BoldText>Дату выполния</S.BoldText>.</li>
                <li>Нажмите <S.BoldText>«Добавить задачу»</S.BoldText>.</li>
              </ul>
            </S.TextRow>

            <S.TextRow>
              <S.P1>
                Начало работы с задачей:
                </S.P1>
            </S.TextRow>

            <S.TextRow>
              <ul className="l-ul">
                <li>Откройте одну из задач, например <S.BoldText>«Согласование технического задания»</S.BoldText>.</li>
                <li>На экран задачи нажмите <S.BoldText>«Взять в работу»</S.BoldText>.</li>
                <li>Выберите ответственного и дату выполнения. Нажмите кнопку <S.BoldText>«Сохранить»</S.BoldText>.</li>
              </ul>
            </S.TextRow>

            <S.TextRow>
              <S.P1>
                Если приложением пользуетесь только вы, то ответственным в задаче нужно выбрать себя. Если ответственный не вы, то исполнителю придёт уведомление, что ему назначена задача. Ответственный должен подтвердить задачу, нажав кнопку <S.BoldText>«Взять в работу»</S.BoldText>.
              </S.P1>
            </S.TextRow>

            <S.TextRow>
              <S.InfoCard>
                <S.InfoCardIcon><Icon name="statusDanger" /></S.InfoCardIcon>
                <S.P1>Важно! "Владельцу" и "Управляющему" проекта доступны все задачи. Исполнителям — дизайнерам, строителям — будут доступны только те задачи, которые им назначили или они сами создали.</S.P1>
              </S.InfoCard>
            </S.TextRow>

            <S.TextRow>
              <ul className="l-ul">
                <li>После этого задача переходит в статус <S.BoldText>«В работе»</S.BoldText>, тем самым фиксируется дата начала задачи и то, что ответственный взял ее в работу. Свидетельством этому служит «Событие», которое отобразилось на экране <S.BoldText>«Журнала событий»</S.BoldText>.</li>
              </ul>
            </S.TextRow>

            <S.TextRow>
              <ul className="l-ul">
                <li>В верхней панели нажмите <S.BoldText>«О Проекте»</S.BoldText>, откройте <S.BoldText>«Журнал событий»</S.BoldText> и просмотрите ленту, найдите событие «Задача «Согласование технического задания» работе». Нажмите на неё, и вы снова попадаете на основной экран задачи.</li>
              </ul>
            </S.TextRow>

            <S.H3 id="install_notes">Как работать с «Заметками»?</S.H3>

            <S.TextRow>
              <LandingComponentsVideo url='https://s3video.ams3.digitaloceanspaces.com/landing/install_ios/notes.mp4'/>
            </S.TextRow>

            <S.TextRow>
              <S.P1>
                <S.BoldText>«Заметки»</S.BoldText> — это  чат внутри задачи, который доступен для всех участников проекта. В нём можно вести переписку и обсуждать вопросы, а также оставлять для себя напоминания.
              </S.P1>
            </S.TextRow>

            <S.TextRow>
              <ul className="l-ul">
                <li>Откройте одну из задач.</li>
                <li>Нажмите на <S.BoldText>«Заметки»</S.BoldText>.</li>
                <li>В строке чата напишите сообщение, например: «Когда будет готов черновик?»</li>
                <li>Убедитесь, что сообщение появилось в ленте чата. Если ответственный за работу другое лицо, то вся информация, оставленная в заметках, автоматически отображается в его ленте.</li>
              </ul>
            </S.TextRow>

            <S.TextRow>
              <S.InfoCard>
                <S.InfoCardIcon><Icon name="statusDanger" /></S.InfoCardIcon>
                <S.P1>Вся информация, оставленная в заметках, дублируется на экране «Журнал событий» в хронологическом порядке. Это позволит быстро найти необходимую запись, сообщение или напоминание. </S.P1>
              </S.InfoCard>
            </S.TextRow>

            <S.H3 id="install_docs">Как работать с «Документами»?</S.H3>

            <S.TextRow>
              <LandingComponentsVideo url='https://s3video.ams3.digitaloceanspaces.com/landing/install_ios/docs.mp4'/>
            </S.TextRow>

            <S.TextRow>
              <S.P1>
              <S.BoldText>«Документы»</S.BoldText> — удобный архив для хранения фото, чертежей, договоров и другой документации внутри задачи, который доступен для всех участников проекта. В приложении можно  работать со всем списком документов из единого меню. Нет необходимости переходить по разным задачам, чтобы найти нужные файлы или документы. Их можно найти, пролистав ленту экрана <S.BoldText>«Документы»</S.BoldText>.
              </S.P1>
            </S.TextRow>

            <S.TextRow>
              <S.P1>
                Как добавить документ?
              </S.P1>
            </S.TextRow>

            <S.TextRow>
              <ul className="l-ul">
                <li>Откройте одну из задач.</li>
                <li> Нажмите <S.BoldText>«Документы»</S.BoldText>.</li>
                <li>Чтобы добавить необходимый файл, нажмите иконку <S.BoldText>«+»</S.BoldText>.</li>
                <li>В меню выбора нажмите <S.BoldText>«Добавить файл»</S.BoldText>.</li>
                <li>Выберите документ, который хотите загрузить.</li>
                <li>Нажмите <S.BoldText>«Сохранить»</S.BoldText>.</li>
              </ul>
            </S.TextRow>

            <S.TextRow>
              <S.P1>
                Если нужно сделать фото:
              </S.P1>
            </S.TextRow>

            <S.TextRow>
              <ul className="l-ul">
                <li>В меню выбора нажмите <S.BoldText>«Сделать фото»</S.BoldText>.</li>
                <li>Сделайте фото и нажмите <S.BoldText>«Использовать»</S.BoldText>.</li>
                <li>Нажмите <S.BoldText>«Сохранить»</S.BoldText>.</li>
              </ul>
            </S.TextRow>

            <S.TextRow>
              <S.P1>
                Если нужно загрузить фото:
              </S.P1>
            </S.TextRow>

            <S.TextRow>
              <ul className="l-ul">
                <li>В меню выбора нажмите <S.BoldText>«Библиотека фото»</S.BoldText>.</li>
                <li>Выберите фото, которое хотите загрузить.</li>
                <li>Нажмите <S.BoldText>«Сохранить»</S.BoldText>.</li>
              </ul>
            </S.TextRow>

            <S.TextRow>
              <S.P1>
                Для платформы IOS возможен прямой импорт документов прямо из других приложений.
              </S.P1>
            </S.TextRow>

            <S.TextRow>
              <LandingComponentsVideo url='https://s3video.ams3.digitaloceanspaces.com/landing/8.mp4'/>
            </S.TextRow>

            <S.TextRow>
              <ul className="l-ul">
                <li>В галерее <S.BoldText>«Фото»</S.BoldText>  откройте фотографию, которую нужно сохранить в приложении. </li>
                <li>Нажмите на иконку <S.BoldText>«Поделиться»</S.BoldText>.</li>
                <li>В открывшемся окне нажмите на иконку приложения <S.BoldText>"ProGresto"</S.BoldText>.</li>
                <li>В открывшемся приложении выберите задачу, к которой нужно добавить документ.</li>
                <li>Нажмите <S.BoldText>«Сохранить»</S.BoldText>.</li>
              </ul>
            </S.TextRow>

            <S.TextRow>
              <S.InfoCard>
                <S.InfoCardIcon><Icon name="statusDanger" /></S.InfoCardIcon>
                <S.P1>Информация о добавленных файлах дублируется на экране «Журнал событий» в хронологическом порядке. Это позволит быстро найти нужные фото или документы.</S.P1>
              </S.InfoCard>
            </S.TextRow>

            <S.H3 id="install_cost">Как работать с «Затратами»?</S.H3>

            <S.TextRow>
              <LandingComponentsVideo url='https://s3video.ams3.digitaloceanspaces.com/landing/install_ios/expenses.mp4'/>
            </S.TextRow>

            <S.TextRow>
              <S.P1>
                Затраты отражают и хранят всю финансовую отчётность внутри задачи. Доступно для тех участников проекта, которые в неё добавлены. Дополнительная функция: затраты позволяют сопоставлять планируемые и фактические траты.
              </S.P1>
            </S.TextRow>

            <S.TextRow>
              <ul className="l-ul">
               <li>Откройте одну из задач.</li>
                <li>Нажмите <S.BoldText>«Затраты»</S.BoldText>.</li>
                <li>Нажмите кнопку <S.BoldText>«+»</S.BoldText>.</li>
                <li>В новом экране укажите название затраты. Например, <S.BoldText>«Паркетные доски»</S.BoldText>.</li>
                <li>Укажите планируемую цену. Например, 5000 рублей.</li>
                <li>Если затрата уже оплачена, выберите статус <S.BoldText>«Оплачена»</S.BoldText> и укажите фактическую стоимость.</li>
                <li>Нажмите кнопку <S.BoldText>«Добавить»</S.BoldText>.</li>
                <li>Проверьте, что в ленте <S.BoldText>«Затрат»</S.BoldText> появилась затрата с названием <S.BoldText>«Паркетные доски»</S.BoldText>.</li>
                </ul>
                </S.TextRow>

                <S.TextRow>
                <S.P1>
                  Для более подробного отслеживания затрат, заполните дополнительные поля:
                </S.P1>
                </S.TextRow>

              <S.TextRow>
               <ul className="l-ul">
                <li>Категория</li>
                <li>Купить до</li>
                <li>Количество</li>
                <li>Единица измерения</li>
                <li>Комментарий</li>
                </ul>
              </S.TextRow>

               <S.TextRow>
                <S.P1>
                  Чтобы зафиксировать совершенную покупку:
                </S.P1>
               </S.TextRow>

               <S.TextRow>
                <ul className="l-ul">
                <li>Нажмите на затрату <S.BoldText>«Паркетные доски»</S.BoldText>.</li>
                <li>В открывшемся окне в поле чек/квитанция нажмите на иконку <S.BoldText>«+»</S.BoldText>.</li>
                <li>Добавьте фото чека.</li>
              
                <li>Нажмите кнопку <S.BoldText>«Оплачено»</S.BoldText>.</li>
                <li>В поле <S.BoldText>«Оплачено по цене»</S.BoldText>  укажите фактическую стоимость услуги – 5600 руб.</li>
                <li>Нажмите кнопку <S.BoldText>«Сохранить»</S.BoldText>.</li>
                <li>Проверьте, что в затрате <S.BoldText>«Паркетные доски»</S.BoldText> появились фактическая и планируемая цена, а также значок скрепки, что означает, что к данной затрате был добавлен чек.</li>
                </ul>
               </S.TextRow>


            <S.TextRow>
              <S.P1>
                Приложение позволяет работать со всеми затратами на едином экране. Нет необходимости переходить по разным задачам. Их можно найти, пролистав ленту экрана <S.BoldText>«Затраты»</S.BoldText>. Также данный экран отображает общую сумму затраченных средств на данный момент и общий бюджет стройки, которая запланирована. Приложение считает их автоматически.
              </S.P1>
            </S.TextRow>

            <S.TextRow>
              <S.InfoCard>
                <S.InfoCardIcon><Icon name="statusDanger" /></S.InfoCardIcon>
                <S.P1>Информация о затратах дублируется на экране «Журнал событий» в хронологическом порядке. Это позволит быстро найти нужные затраты и чеки.</S.P1>
              </S.InfoCard>
            </S.TextRow>


          </div>
        </div>
      </div>

      <div id="section-cb" className="text-2 text-center" style={{ marginTop: '164px' }}>Обратная связь</div>
      <div className="text-1 text-center" style={{ marginBottom: '44px' }}>Присылайте ваши вопросы и пожелания.</div>

      <LandingFeedbackReduxForm onSubmit={onFeedbackSubmit} />
      <LandingFooter />
    </S.Container>
  );
}

export default InstallIos;
