import { isLoadingSelector } from 'app/modules/landing/store/feedback/selectors';
import UDInput from 'app/modules/ud-forms/form-input/input';
import UDTextarea from 'app/modules/ud-forms/textarea/textarea';
import { email, mobilePhone, requiredCreator } from 'app/modules/validation/common-validator';
import React from 'react';
import { useSelector } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

const nameRequired = requiredCreator("Введите имя");
const emailRequired = requiredCreator("Введите email");
const phoneRequired = requiredCreator("Введите телефон");
const messageRequired = requiredCreator("Введите сообщение");

const LandingFeedBackForm = ({ handleSubmit, error, pristine, submitting, invalid }: any) => {
  const isLoading = useSelector(isLoadingSelector);
  return (
    <form onSubmit={handleSubmit}>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 offset-lg-3">
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <Field component={UDInput}
                    placeholder="Имя"
                    validate={[nameRequired]}
                    disabled={isLoading}
                    name="name" />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="form-group">
                  <Field component={UDInput}
                    placeholder="E-mail"
                    validate={[email, emailRequired]}
                    disabled={isLoading}
                    name={"email"} />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <Field component={UDInput}
                    placeholder="Телефон"
                    validate={[phoneRequired, /* mobilePhone */]}
                    disabled={isLoading}
                    name={"phone"} />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <Field component={UDTextarea}
                    rows={6}
                    placeholder="Сообщение"
                    validate={[messageRequired]}
                    disabled={isLoading}
                    name={"message"} />
                </div>
              </div>
            </div>
            <button className="btn btn-block">Отправить</button>
            <div className="form-tip">Нажимая на кнопку, вы даете согласие на обработку своих персональных данных.</div>
          </div>
        </div>
      </div>
    </form>
  )
};

const LandingFeedbackReduxForm = reduxForm<any>({ form: 'landing_feedback' })(LandingFeedBackForm);

export default LandingFeedbackReduxForm;
