export const breakpoints = {
  xs: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px'
};

export const theme = {
  up: (breakpoint: string, vertical = false) => `@media (min-${vertical ? 'height' : 'width'}: calc(${breakpoint} + 0.02px))`,
  down: (breakpoint: string, vertical = false) => `@media (max-${vertical ? 'height' : 'width'}: ${breakpoint})`,
  between: (breakpointMin: string, breakpointMax: string, vertical = false) => `@media (max-${vertical ? 'height' : 'width'}: ${breakpointMax}) and (min-${vertical ? 'height' : 'width'}: calc(${breakpointMin} + 0.02px))`,
  breakpoints
};