import React from 'react';
import './styles/libs/bootstrap.scss'
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './styles/styles.scss'
import AppRouter from "./app/modules/navigation/routes";
import { ToastContainer } from 'react-toastify';
import { ThemeProvider } from 'styled-components';
import { theme } from 'styles/styled-theme';
import { Provider } from "react-redux";
import store from './store';


const App = () => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <div className="container-fluid m-0 p-0" style={{ height: "100%" }}>
          <AppRouter />
          <ToastContainer
            position="top-right"
            hideProgressBar={true}
            pauseOnHover
            closeOnClick
            closeButton={false} autoClose={10000} />
        </div>
      </ThemeProvider>
    </Provider>
  )
}

export default App;
