import Icon from 'icons';
import React, { useState } from 'react';

type Props = {
  title: string
  text: string
}
const Accordion = (props: Props) => {
  const { title, text } = props;
  const [isOpened, setOpenedState] = useState(false);

  const onToggle = () => {
    setOpenedState(!isOpened);
  };

  const btnClassNames = isOpened ? 'acc-btn acc-btn--opened' : 'acc-btn';

  return (
    <div className="acc">
      <div onClick={onToggle} className="acc-header">
        <div className="acc-title">{title}</div>
        <button type="button" className={btnClassNames}>
          <Icon name="arrow"/>
        </button>
      </div>
      {isOpened && (<div className="acc-content">{text}</div>) }
    </div>
  )
};

export default Accordion;
