import { createSlice } from "@reduxjs/toolkit";
import { sendFeedback } from "./actions";

export type LandingFeedbackState = {
  isLoading: boolean
};

const initialState: LandingFeedbackState = {
  isLoading: false
};

type Reducers = {}

const landingFeedbackSlice = createSlice<LandingFeedbackState, Reducers>({
  name: "landing/feedback",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(sendFeedback.pending, (state: LandingFeedbackState, action: any) => {
      state.isLoading = true;
    });
    builder.addCase(sendFeedback.fulfilled, (state: LandingFeedbackState, action: any) => {
      state.isLoading = false;
    });
    builder.addCase(sendFeedback.rejected, (state: LandingFeedbackState, action: any) => {
      state.isLoading = false;
    });
  },
});

const landingFeedbackReducer = landingFeedbackSlice.reducer;
export const LandingFeedbackActions = landingFeedbackSlice.actions;

export default landingFeedbackReducer;

