import React from "react";
import { StyledTextarea } from "./styles";
import UDErrorMessage from "../error-message/input";


const UDTextarea = ({ input, meta, ...props }: any) => {
    const hasError = meta?.touched && meta?.error
    return (
        <>
            <StyledTextarea hasError={hasError} {...input} {...props} />
            {hasError && <UDErrorMessage error={meta.error}/>}
        </>
    )
};

export default UDTextarea;