import React from "react";
import ReactPlayer from "react-player";

type Props = {
  url: string
}
const LandingComponentsVideo = (props: Props) => {
  return (
    <ReactPlayer
      width='100%'
      url={props.url}
      controls={true} />
  )
};

export default LandingComponentsVideo;