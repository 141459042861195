import React from 'react';

import logoLabel from './img/logo-label.png';
import logoLabel2x from './img/logo-label@2x.png';

import ios from './img/ios.png';
import ios2x from './img/ios@2x.png';

import android from './img/android.png';
import android2x from './img/android@2x.png';

import iphone from './img/iphone.png';
// import iphone2x from './img/iphone@2x.png';

import ic1 from './img/ic-1.png';
import ic12x from './img/ic-1@2x.png';

import ic2 from './img/ic-2.png';
import ic22x from './img/ic-2@2x.png';

import ic3 from './img/ic-3.png';
import ic32x from './img/ic-3@2x.png';

import ic4 from './img/ic-4.png';
import ic42x from './img/ic-4@2x.png';

import ic5 from './img/ic-5.png';
import ic52x from './img/ic-5@2x.png';

import ic6 from './img/ic-6.png';
import ic62x from './img/ic-6@2x.png';

import ic7 from './img/ic-7.png';
import ic72x from './img/ic-7@2x.png';

import ic8 from './img/ic-8.png';
import ic82x from './img/ic-8@2x.png';

import ic9 from './img/ic-9.png';
import ic92x from './img/ic-9@2x.png';

import ic10 from './img/ic-10.png';
import ic102x from './img/ic-10@2x.png';

import ic11 from './img/ic-11.png';
import ic112x from './img/ic-11@2x.png';

import ic12 from './img/ic-12.png';
import ic122x from './img/ic-12@2x.png';

import arrow from './img/arrow.png';
import arrow2x from './img/arrow@2x.png';

import menuOpen from './img/menu.png';
import menuOpen2x from './img/menu@2x.png';

import menuClose from './img/menu-close.png';
import menuClose2x from './img/menu-close@2x.png';

import modalClose from './img/close.png';
import modalClose2x from './img/close@2x.png';

import titleFiles from './img/title-files.png';
import titleFiles2x from './img/title-files@2x.png';

import titleHome from './img/title-home.png';
import titleHome2x from './img/title-home@2x.png';

import titleSettings from './img/title-settings.png';
import titleSettings2x from './img/title-settings@2x.png';

import titleTasks from './img/title-tasks.png';
import titleTasks2x from './img/title-tasks@2x.png';

import titleWallet from './img/title-wallet.png';
import titleWallet2x from './img/title-wallet@2x.png';

import statusDanger from './img/status-danger.png';
import statusDanger2x from './img/status-danger@2x.png';

import instagram from './img/ic-instagram.png';
import instagram2x from './img/ic-instagram@2x.png';

import facebook from './img/ic-facebook.png';
import facebook2x from './img/ic-facebook@2x.png';

const iconsMap = {
  logoLabel: {
    src: logoLabel,
    src2x: logoLabel2x,
    alt: 'Лого',
    width: 183,
    height: 'auto'
  },
  ios: {
    src: ios,
    src2x: ios2x,
    alt: 'Скачать из App Store',
    width: 26,
    height: 32
  },
  android: {
    src: android,
    src2x: android2x,
    alt: 'Скачать из Google Play',
    width: 36,
    height: 36
  },
  iphone: {
    src: iphone,
    // src2x: iphone2x,
    alt: 'ProGresto Проект',
    width: 425,
    height: 856,
    isBigImage: true
  },
  ic1: {
    src: ic1,
    src2x: ic12x,
    alt: 'Детальный план ремонта',
    width: 64,
    height: 64
  },
  ic2: {
    src: ic2,
    src2x: ic22x,
    // alt: 'Детальный план ремонта',
    width: 64,
    height: 64
  },
  ic3: {
    src: ic3,
    src2x: ic32x,
    // alt: 'Детальный план ремонта',
    width: 64,
    height: 64
  },
  ic4: {
    src: ic4,
    src2x: ic42x,
    // alt: 'Детальный план ремонта',
    width: 64,
    height: 64
  },
  ic5: {
    src: ic5,
    src2x: ic52x,
    // alt: 'Детальный план ремонта',
    width: 64,
    height: 64
  },
  ic6: {
    src: ic6,
    src2x: ic62x,
    // alt: 'Детальный план ремонта',
    width: 64,
    height: 64
  },
  ic7: {
    src: ic7,
    src2x: ic72x,
    // alt: 'Детальный план ремонта',
    width: 64,
    height: 64
  },
  ic8: {
    src: ic8,
    src2x: ic82x,
    // alt: 'Детальный план ремонта',
    width: 64,
    height: 64
  },
  ic9: {
    src: ic9,
    src2x: ic92x,
    // alt: 'Детальный план ремонта',
    width: 64,
    height: 64
  },
  ic10: {
    src: ic10,
    src2x: ic102x,
    // alt: 'Детальный план ремонта',
    width: 64,
    height: 64
  },
  ic11: {
    src: ic11,
    src2x: ic112x,
    // alt: 'Детальный план ремонта',
    width: 64,
    height: 64
  },
  ic12: {
    src: ic12,
    src2x: ic122x,
    // alt: 'Детальный план ремонта',
    width: 64,
    height: 64
  },

  arrow: {
    src: arrow,
    src2x: arrow2x,
    // alt: 'Детальный план ремонта',
    width: 16,
    height: 8
  },

  menuOpen: {
    src: menuOpen,
    src2x: menuOpen2x,
    width: 24,
    height: 24
  },
  menuClose: {
    src: menuClose,
    src2x: menuClose2x,
    width: 24,
    height: 24
  },

  modalClose: {
    src: modalClose,
    src2x: modalClose2x,
    width: 16,
    height: 16
  },

  titleFiles: {
    src: titleFiles,
    src2x: titleFiles2x,
    width: 24,
    height: 24
  },

  titleHome: {
    src: titleHome,
    src2x: titleHome2x,
    width: 24,
    height: 24
  },
  titleSettings: {
    src: titleSettings,
    src2x: titleSettings2x,
    width: 24,
    height: 24
  },
  titleTasks: {
    src: titleTasks,
    src2x: titleTasks2x,
    width: 24,
    height: 24
  },
  titleWallet: {
    src: titleWallet,
    src2x: titleWallet2x,
    width: 24,
    height: 24
  },

  statusDanger: {
    src: statusDanger,
    src2x: statusDanger2x,
    width: 24,
    height: 24
  },

  instagram: {
    src: instagram,
    src2x: instagram2x,
    width: 24,
    height: 24
  },

  facebook: {
    src: facebook,
    src2x: facebook2x,
    width: 24,
    height: 24
  },
  
};

type Props = {
  name: string
  maxWidth?: string
  maxHeight?: string
  className?: string
};
const Icon = (props: Props) => {
  const { src, src2x, alt, width, height, isBigImage } = (iconsMap as any)[props.name];
  const styles = isBigImage
    ? {
      maxWidth: `${width}px`,
      maxHeight: `${height}px`,
    }
    : {
      maxWidth: '100%',
      maxHeight: '100%',
      width: `${width}px`, height: `${height}px`
    };

  if (props.maxWidth != null) {
    styles.maxWidth = props.maxWidth;
  }

  if (props.maxHeight != null) {
    styles.maxHeight = props.maxHeight;
  }

  return (
    <img style={styles} src={src} srcSet={src2x} alt={alt} className={props.className} />
  );
};

export default Icon;
