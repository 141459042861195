import React, { FC, useCallback } from "react";
import { HiddenCheckbox, StyledCheckbox, Icon, LabelText } from "./styles";
import CheckedIcon from "../../../icons/CheckedIcon"

type Props = {
	label?: string
	value?: string
	name?: string
	checked?: boolean
	disabled?: boolean
	onChange: (e: any) => void
	onClick?: (e: any) => void
}
const UDCheckboxCmp: FC<Props> = (props: Props) => {
	const { name, label, value, checked, onChange, disabled, onClick } = props;
	const onCheckboxChanged = useCallback((e) => {
		onChange(e.target.checked);
	}, [onChange]);

	const onClicked = (e: any) => {
		e.stopPropagation();
		onClick(e);
	};

	return (
		<label className="d-inline-flex align-items-start" onClick={onClicked}>
			<HiddenCheckbox
				disabled={disabled}
				name={name}
				value={value}
				checked={checked}
				onChange={onCheckboxChanged}
			/>
			<StyledCheckbox checked={checked}>
				<Icon>
					<CheckedIcon />
				</Icon>
			</StyledCheckbox>
			<LabelText className="d-inline-block ml-2">{label}</LabelText>
		</label>
	)
}

export default UDCheckboxCmp;
