import ReactInputMask from 'react-input-mask';
import styled from 'styled-components';

type InputProps = {
    hasError: boolean,
    icon: any
}
export const StyledInput = styled(ReactInputMask)`
    background: #FFFFFF;
    border: 2px solid #EEEDF1;
    border-radius: 12px;
    font-size: 16px;
    line-height: 22px;
    padding: 9px 14px;
    width: 100%;
    transition: all 100ms ease-in-out;

    ${(props: InputProps) =>
        props.hasError && "border-color: #D91513"
    };

    &:focus {
        outline: none;
        border-color: #25224A;
    }
`;

export const StyledLgInput = styled(StyledInput)`
    padding: 14px 16px;
`;
