import React from "react";

const CheckedIcon = () => {
    return (
        <svg style={{ display: 'block' }} width="14" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="16" height="16" rx="2" fill="#25224A" />
            <path d="M4 9L6 11L12 5" stroke="white" strokeWidth="2" strokeLinecap="round" />
        </svg>
    )
}

export default CheckedIcon;