import React, { FC } from "react";
import UDCheckboxCmp from "./component";

type Props = {
    label?: string
    name?: string
    id?: string
    input: any
}
const UDCheckbox: FC<Props> = (props: Props) => {
    const { input, ...otherProps } = props;
    const { value: isChecked, onChange } = input;

    return (
        <UDCheckboxCmp
            {...otherProps}
            checked={isChecked}
            onChange={onChange}/>
    )
}

export default UDCheckbox;
