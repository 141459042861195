import Icon from 'icons';
import React, { useCallback } from 'react';
import LandingFooter from '../../components/footer';

import * as S from "./styles";
import { useDispatch } from 'react-redux';
import LandingFeedbackReduxForm from '../../components/feedback-form';
import { sendFeedback } from 'app/modules/landing/store/feedback/actions';
import LandingComponentsInstallReduxForm from '../../components/install-form';
import LandingComponentsVideo from '../../components/video';

const InstallAndroid = () => {
  const dispatch = useDispatch();
  
  const onFeedbackSubmit = useCallback((info: any) => {
    dispatch(sendFeedback(info));
  }, []);
  
  return (
    <S.Container>
      <div className="container">
        <div className="row">
          <div className="col-lg-3">
            <S.FixedSidebar>
              <S.FixedSidebarContent>
                <S.CheckTitle>
                  <S.CheckTitleLeft>Чек-</S.CheckTitleLeft>
                  <S.CheckTitleLeft>лист</S.CheckTitleLeft>
                </S.CheckTitle>

                <LandingComponentsInstallReduxForm />
              </S.FixedSidebarContent>
            </S.FixedSidebar>
          </div>
          <div className="col-lg-9">

  
            <S.H3 noTopMargin id="install_reg" className="mt-4 mt-lg-0">Регистрация аккаунта в приложении ProGresto</S.H3>

            <S.TextRow>
              <LandingComponentsVideo url='https://s3video.ams3.digitaloceanspaces.com/landing/1.mp4'/>
            </S.TextRow>

            <S.TextRow>
              <S.P1>
                Если вы уже зарегистрировались на сайте, в приложение можно зайти по тому же логину и паролю. Если нет, то выполните следующие действия:
              </S.P1>
            </S.TextRow>

            <S.TextRow>
              <S.P1>
                <S.BlueText>1.</S.BlueText> Запустите приложение.
              </S.P1>
            </S.TextRow>

            <S.TextRow>
              <S.P1>
                <S.BlueText>2.</S.BlueText> Нажмите кнопку <S.BoldText>«Регистрация»</S.BoldText>.
              </S.P1>
            </S.TextRow>

            <S.TextRow>
              <S.P1>
                <S.BlueText>3.</S.BlueText> Заполните необходимые поля для регистрации: имя, телефон, пароль. Выберете из предложенных вариантов свою роль.
              </S.P1>
            </S.TextRow>


            <S.TextRow>
              <S.P1>
                <S.BlueText>4.</S.BlueText> Введите код из смс, который был отправлен на указанный номер для подтверждения регистрации.
              </S.P1>
            </S.TextRow>

            <S.H2 id="install_project">Создание проекта в приложении ProGresto</S.H2>

            <S.TextRow>
              <LandingComponentsVideo url='https://s3video.ams3.digitaloceanspaces.com/landing/2.mp4'/>
            </S.TextRow>

            <S.TextRow>
              <S.P1>
                Чтобы начать работу над вашим ремонтом, нужно создать проект.
              </S.P1>
            </S.TextRow>

            <S.TextRow>
              <S.P1>
                <S.BlueText>1.</S.BlueText> Если у вас Android, то нажмите кнопку <S.BoldText>«+»</S.BoldText> для добавления первого проекта.
              </S.P1>
            </S.TextRow>

            <S.TextRow>
              <S.P1>
                <S.BlueText>2.</S.BlueText> Выберите тип объекта, заполните поля «название проекта», «адрес», «площадь» и нажмите кнопку <S.BoldText>«Сохранить»</S.BoldText>.
              </S.P1>
            </S.TextRow>

            <S.TextRow>
              <S.P1>
                <S.BlueText>3.</S.BlueText> Пригласите людей в ваш проект, нажав кнопку <S.BoldText>«Добавить приглашение»</S.BoldText>. Приложение позволяет работать над проектом сразу всем участникам ремонта — клиент, строители,  дизайнеры.  На экране <S.BoldText>«Добавить приглашение»</S.BoldText> выберите роль участника, укажите его номер телефона и нажмите кнопку <S.BoldText>«Пригласить»</S.BoldText>.
              </S.P1>
            </S.TextRow>

            <S.TextRow>
              <S.InfoCard>
                <S.InfoCardIcon><Icon name="statusDanger" /></S.InfoCardIcon>
                <S.P1>Важно! Чтобы человек мог добавиться в ваш проект, у него должен быть зарегистрированный аккаунт в приложении ProGresto.</S.P1>
              </S.InfoCard>
            </S.TextRow>

            <S.TextRow>
              <S.P1>
                Если других участников команды пока нет, то нажмите кнопку <S.BoldText>«Сделаю позже»</S.BoldText>.
              </S.P1>
            </S.TextRow>

            <S.TextRow>
              <S.P1>
                <S.BlueText>4.</S.BlueText> Утвердите план работ. Для экономии времени вы можете воспользоваться готовым списком типовых задач для ремонта. Если вам не понадобится типовой список в полном объёме, отключите эту функцию и вручную добавьте  необходимые задачи, которые вы можете найти в категориях «Дизайн», «Ремонт» и «Магазин». При необходимости дополните готовый список своими пунктами при помощи кнопки <S.BoldText>«Создать новую задачу»</S.BoldText> внутри каждой из категорий.
              </S.P1>
            </S.TextRow>

            <S.TextRow>
              <S.P1>
                <S.BlueText>5.</S.BlueText> Для начала работы над проектом, Нажмите кнопку <S.BoldText>«Утвердить»</S.BoldText>.
              </S.P1>
            </S.TextRow>

            <S.TextRow>
              <S.InfoCard>
                <S.InfoCardIcon><Icon name="statusDanger" /></S.InfoCardIcon>
                <S.P1>Важно! Утвердить проект может создатель проекта и участник с ролью «Управляющий»</S.P1>
              </S.InfoCard>
            </S.TextRow>
  
  
  
  
            {/* <S.TextRow>
              <S.P1>
                <S.BlueText>3.</S.BlueText> Для экономии времени вы можете воспользоваться готовым списком типовых задач для ремонта. Для этого:
              </S.P1>
            </S.TextRow>

            <S.TextRow>
              <ul className="l-ul">
                <li>На экране «Задачи» просмотрите содержание представленных категорий (дизайн, ремонт, магазин), под каждую из которых есть готовый список задач.</li>
                <li>При необходимости дополните готовый список своими пунктами при помощи кнопки <S.BoldText>«Создать новую задачу»</S.BoldText>.</li>
                <li>Нажмите кнопку <S.BoldText>«Продолжить»</S.BoldText>.</li>
              </ul>
            </S.TextRow> */}
  
            {/* <S.TextRow>
              <S.P1>
                <S.BlueText>4.</S.BlueText> Пригласите людей в ваш проект, нажав кнопку <S.BoldText>«Добавить приглашение»</S.BoldText>. Приложение позволяет работать над проектом сразу всем участникам ремонта — клиент, строители,  дизайнеры.  На экране «Добавить приглашение» выберите роль участника, укажите его номер телефона и нажмите кнопку <S.BoldText>«Пригласить»</S.BoldText>.
              </S.P1>
            </S.TextRow> */}
  
            {/* <S.TextRow>
              <S.InfoCard>
                <S.InfoCardIcon><Icon name="statusDanger" /></S.InfoCardIcon>
                <S.P1>Чтобы человек мог добавиться в ваш проект, у него должен быть зарегистрированный аккаунт в приложении ProGresto.</S.P1>
              </S.InfoCard>
            </S.TextRow>

            <S.P1>
              Если других участников команды пока нет, то нажмите кнопку <S.BoldText>«Сделаю позже»</S.BoldText>.
            </S.P1> */}
  
  
  
            <S.H3>Изменение настроек проекта</S.H3>

            <S.TextRow>
              <LandingComponentsVideo url='https://s3video.ams3.digitaloceanspaces.com/landing/3.mp4'/>
            </S.TextRow>

            <S.TextRow>
              <S.P1>
                Экран «Настройки проекта» позволяет приглашать или удалять участников команды, а также изменять характеристики объекта ремонта.
              </S.P1>
            </S.TextRow>

            <S.H3>Как пригласить людей в проект?</S.H3>

            <S.TextRow>
              <S.P1>
                <S.BlueText>1.</S.BlueText> Нажмите иконку <S.BoldText>«Настройки»</S.BoldText> в нижней панели.
              </S.P1>
            </S.TextRow>

            <S.TextRow>
              <S.P1>
                <S.BlueText>2.</S.BlueText> Выберите закладку <S.BoldText>«Команда»</S.BoldText>.
              </S.P1>
            </S.TextRow>

            <S.TextRow>
              <S.P1>
                <S.BlueText>3.</S.BlueText> Нажмите кнопку <S.BoldText>«Пригласить»</S.BoldText>.
              </S.P1>
            </S.TextRow>

            <S.TextRow>
              <S.P1>
                <S.BlueText>4.</S.BlueText> Выберите <S.BoldText>«Добавить приглашение»</S.BoldText>.
              </S.P1>
            </S.TextRow>

            <S.TextRow>
              <S.P1>
                <S.BlueText>5.</S.BlueText> На экране «Добавить приглашение» выберите роль участника — клиент, строитель или дизайнер — и укажите его телефон.
              </S.P1>
            </S.TextRow>

            <S.TextRow>
              <S.P1>
                <S.BlueText>6.</S.BlueText> Нажмите кнопку <S.BoldText>«Добавить»</S.BoldText>.
              </S.P1>
            </S.TextRow>

            <S.TextRow>
              <S.P1>
                <S.BlueText>7.</S.BlueText> Если указанный участник команды уже зарегистрировался, то его имя должно появится в списке участников команды.
              </S.P1>
            </S.TextRow>

            <S.H3>Как изменить характеристики проекта?</S.H3>

            <S.TextRow>
              <S.P1>
                <S.BlueText>1.</S.BlueText> Нажмите на закладку <S.BoldText>«Объект»</S.BoldText>.
              </S.P1>
            </S.TextRow>

            <S.TextRow>
              <S.P1>
                <S.BlueText>2.</S.BlueText> Если вы владелец проекта, у вас будет кнопка <S.BoldText>«Изменить»</S.BoldText>. Нажмите её.
              </S.P1>
            </S.TextRow>

            <S.TextRow>
              <S.P1>
                <S.BlueText>3.</S.BlueText> В открывшемся экране нажмите на кнопку <S.BoldText>«Дополнительные поля»</S.BoldText>.
              </S.P1>
            </S.TextRow>

            <S.TextRow>
              <S.P1>
                <S.BlueText>4.</S.BlueText> Заполните одно из предложенных полей.
              </S.P1>
            </S.TextRow>

            <S.TextRow>
              <S.P1>
                <S.BlueText>5.</S.BlueText> Нажмите кнопку <S.BoldText>«Сохранить»</S.BoldText>.
              </S.P1>
            </S.TextRow>

            <S.TextRow>
              <S.P1>
                <S.BlueText>6.</S.BlueText> Проверьте, что изменения которые вы внесли, появились на экране «Параметры объекта».
              </S.P1>
            </S.TextRow>

            <S.H2 id="install_overview">Обзор основных экранов</S.H2>

            <S.TextRow>
              <LandingComponentsVideo url='https://s3video.ams3.digitaloceanspaces.com/landing/4.mp4'/>
            </S.TextRow>

            <S.TextRow>
              <S.P1>
                Цель данного шага — познакомиться с основными экранами и возможностями данного приложения.
              </S.P1>
            </S.TextRow>

            <S.H3><S.H3IconWrap><Icon name="titleHome" /></S.H3IconWrap>Проект</S.H3>

            <S.TextRow>
              <S.P1>
                Нажмите на иконку «Проект»  в нижней панели. «Проект» — главный  информационный экран приложения, где вы сможете следить за всеми действиями и обновлениями текущего ремонта.
              </S.P1>
            </S.TextRow>

            <S.TextRow>
              <S.P1>
                Верхняя половина экрана отображает список задач, находящихся в работе на данный момент. При нажатии на задачу в списке, приложение переводит вас непосредственно к задаче.
              </S.P1>
            </S.TextRow>

            <S.TextRow>
              <S.P1>
                В центре экрана расположена цветная шкала, которая показывает процент выполненных работ.
              </S.P1>
            </S.TextRow>

            <S.TextRow>
              <S.P1>
                Нижняя половина экрана показывает все события в хронологическом порядке. Что такое события? Это любые действия любого участника проекта: например, добавление фото, новой затраты, чека, сдвиг сроков, вопрос исполнителя или к исполнителю и тд.
              </S.P1>
            </S.TextRow>

            <S.TextRow>
              <S.P1>
                Приложение позволяет вести работу над несколькими проектами одновременно. Для переключения между проектами нажмите кнопку «Все проекты». В списке проектов выберите нужный.
              </S.P1>
            </S.TextRow>


            <S.H3><S.H3IconWrap><Icon name="titleTasks" /></S.H3IconWrap>Задачи</S.H3>

            <S.TextRow>
              <S.P1>
                Нажмите на иконку  «Задачи»  в нижней панели. «Задачи» — экран, который хранит в себе весь список задач, из которых состоит ваш ремонт. Для удобства этот список разделён на три категории: дизайн, ремонт и магазин. У каждой категории есть своя индивидуальная шкала «выполнения».
              </S.P1>
            </S.TextRow>

            <S.TextRow>
              <S.P1>
                Нажмите на одну из категорий и просмотрите весь список предложенных задач: в данный момент все задачи находятся в статусе «новая».
              </S.P1>
            </S.TextRow>

            <S.TextRow>
              <S.P1>
                Вернитесь обратно в меню категорий и проделайте тоже самое с двумя оставшимися категориями.
              </S.P1>
            </S.TextRow>

            <S.H3><S.H3IconWrap><Icon name="titleFiles" /></S.H3IconWrap>Документы</S.H3>

            <S.TextRow>
              <S.P1>
                Нажмите на иконку «Документы»  в нижней панели. Экран служит отдельной папкой для всей документации, фото и других важных материалов, касающихся вашего ремонта.
              </S.P1>
            </S.TextRow>

            <S.H3><S.H3IconWrap><Icon name="titleWallet" /></S.H3IconWrap>Затраты</S.H3>

            <S.TextRow>
              <S.P1>
                Нажмите на иконку «Затраты» в нижней панели. Это калькулятор, который контролирует и подробно расписывает бюджет вашего ремонта.
              </S.P1>
            </S.TextRow>

            <S.H3><S.H3IconWrap><Icon name="titleSettings" /></S.H3IconWrap>Настройки</S.H3>

            <S.TextRow>
              <S.P1>
                Нажмите кнопку «Настройки» в нижней панели. Здесь вы можете внести необходимые изменения в данные объекта ремонта, а также добавить, изменить или убрать участников команды.
              </S.P1>
            </S.TextRow>


            <S.H2 id="install_tasks">Работа с задачами в приложении ProGresto</S.H2>

            <S.TextRow>
              <S.P1>
                Ваш ремонт в приложении структурирован в виде списка задач — работ, которые необходимо выполнить. Все задачи разделены на три категории — дизайн, ремонт и магазин.
              </S.P1>
            </S.TextRow>

            <S.TextRow>
              <ul className="l-ul">
                <li>В категории «Дизайн» обсуждаются вопросы, которые связаны с дизайном.</li>
                <li>В категории «Ремонт» фиксируется процесс строительных работ.</li>
                <li>В категории «Магазин» хранится список задач и покупок, которые нужно сделать для ремонта.</li>
              </ul>
            </S.TextRow>

            <S.H3>Как ставить задачу и назначать ответственных?</S.H3>

            <S.TextRow>
              <LandingComponentsVideo url='https://s3video.ams3.digitaloceanspaces.com/landing/5.mp4'/>
            </S.TextRow>

            <S.TextRow>
              <S.P1>
                После создания проекта все задачи находятся в статусе «Новая». Чтобы начать работу над задачей, ей нужно назначить ответственного и перевести в статус «В работе». Например:
              </S.P1>
            </S.TextRow>

            <S.TextRow>
              <ul className="l-ul">
                <li>Зайдите в категорию «Дизайн», выберите любую задачу, например «Согласование технического задания».</li>
                <li>Выберите дату, к которой задача должна быть выполнена.</li>
                <li>Выберите ответственного. Например, это будет дизайнер. Нажмите кнопку «Сохранить». Ответственному придёт уведомление, что ему назначена задача. Ответственный должен это подтвердить, нажав кнопку «Начать».</li>
              </ul>
            </S.TextRow>

            <S.TextRow>
              <S.P1 style={{ paddingLeft: '64px' }}>
                Если приложением пользуетесь только вы, то ответственным к задаче нужно выбрать себя. После этого нажмите кнопку «Сохранить и начать».
              </S.P1>
            </S.TextRow>

            <S.TextRow>
              <S.InfoCard>
                <S.InfoCardIcon><Icon name="statusDanger" /></S.InfoCardIcon>
                <S.P1>Важно! "Владельцу" и "Управляющему" проекта доступны все задачи. Исполнителям — дизайнерам, строителям — будут доступны только те задачи, которые им назначили или они сами создали.</S.P1>
              </S.InfoCard>
            </S.TextRow>

            <S.TextRow>
              <ul className="l-ul">
                <li>После этого задача переходит в статус «В работе», тем самым фиксируется дата начала задачи и то, что ответственный взял ее в работу. Свидетельством этому служит «Событие», которое отобразилось на экране «Проект».</li>
              </ul>
            </S.TextRow>

            <S.TextRow>
              <S.P1>
                Теперь вы находитесь на основном экране задач. На данный момент мы отложим работу над этим экраном.
              </S.P1>
            </S.TextRow>

            <S.TextRow>
              <ul className="l-ul">
                <li>Нажмите кнопку назад 2 раза.</li>
                <li>В нижней панели нажмите на иконку «Проект», в поле «События» просмотрите ленту, найдите задачу «Согласование технического задания» в работе. Нажмите на неё, и вы снова попадаете на основной экран задач.</li>
              </ul>
            </S.TextRow>

            <S.TextRow>
              <S.P1>
                Экран «Задачи» состоит из 3 основных пунктов: заметки, документы, затраты.
              </S.P1>
            </S.TextRow>

            <S.H3 id="install_notes">Как работать с «Заметками»?</S.H3>

            <S.TextRow>
              <LandingComponentsVideo url='https://s3video.ams3.digitaloceanspaces.com/landing/6.mp4'/>
            </S.TextRow>

            <S.TextRow>
              <S.P1>
                Заметки — это  чат внутри задачи, который доступен для всех участников проекта. В нём можно вести переписку и обсуждать вопросы, а также оставлять для себя напоминания.
              </S.P1>
            </S.TextRow>

            <S.TextRow>
              <ul className="l-ul">
                <li>Переходите на закладку «Заметки»</li>
                <li>В строке чата напишите сообщение, например: «Когда будет готов черновик?»</li>
                <li>Убедитесь, что сообщение появилось в ленте чата. Если ответственный за работу другое лицо, то вся информация, оставленная в заметках, автоматически отображается в его ленте.</li>
              </ul>
            </S.TextRow>

            <S.TextRow>
              <S.InfoCard>
                <S.InfoCardIcon><Icon name="statusDanger" /></S.InfoCardIcon>
                <S.P1>Вся информация, оставленная в заметках, отображается и в поле «События» в хронологическом порядке. Это позволит быстро найти необходимую запись, сообщение или напоминание. </S.P1>
              </S.InfoCard>
            </S.TextRow>

            <S.H3 id="install_docs">Как работать с «Документами»?</S.H3>

            <S.TextRow>
              <LandingComponentsVideo url='https://s3video.ams3.digitaloceanspaces.com/landing/7.mp4'/>
            </S.TextRow>

            <S.TextRow>
              <S.P1>
                Документы — удобный архив для хранения фото, чертежей, договоров и другой документации внутри задачи, который доступен для всех участников проекта. В приложении можно  работать со всем списком документов из единого меню. Нет необходимости переходить по разным задачам, чтобы найти нужные файлы или документы. Их можно найти, пролистав ленту экрана «Документы».
              </S.P1>
            </S.TextRow>

            <S.TextRow>
              <S.P1>
                Как добавить документ?
              </S.P1>
            </S.TextRow>

            <S.TextRow>
              <ul className="l-ul">
                <li>Переходите на закладку «Документы»</li>
                <li>Чтобы добавить необходимый файл, нажмите иконку «+»</li>
                <li>В новом окне нажмите кнопку «Добавить файл»</li>
                <li>Выберите документ, который хотите загрузить</li>
                <li>Нажмите кнопку «Сохранить»</li>
              </ul>
            </S.TextRow>

            <S.TextRow>
              <S.P1>
                Если нужно сделать фото
              </S.P1>
            </S.TextRow>

            <S.TextRow>
              <ul className="l-ul">
                <li>В новом окне нажмите кнопку «Сделать фото»</li>
                <li>Сделайте фото и нажмите «Использовать»</li>
                <li>Нажмите кнопку «Сохранить»</li>
              </ul>
            </S.TextRow>

            <S.TextRow>
              <S.P1>
                Если нужно загрузить фото
              </S.P1>
            </S.TextRow>

            <S.TextRow>
              <ul className="l-ul">
                <li>В новом окне нажмите кнопку «Библиотека фото»</li>
                <li>Выберите фото, которое хотите загрузить</li>
                <li>Нажмите кнопку «Сохранить»</li>
              </ul>
            </S.TextRow>


            <S.TextRow>
              <S.InfoCard>
                <S.InfoCardIcon><Icon name="statusDanger" /></S.InfoCardIcon>
                <S.P1>Все добавленные файлы отображаются  в поле «События» в хронологическом порядке. Это позволит быстро найти нужные фото или документы.</S.P1>
              </S.InfoCard>
            </S.TextRow>

            <S.H3 id="install_cost">Как работать с «Затратами»?</S.H3>

            <S.TextRow>
              <LandingComponentsVideo url='https://s3video.ams3.digitaloceanspaces.com/landing/9.mp4'/>
            </S.TextRow>

            <S.TextRow>
              <S.P1>
                Затраты отражают и хранят всю финансовую отчётность внутри задачи. Доступно для тех участников проекта, которые в неё добавлены. Дополнительная функция: затраты позволяют сопоставлять планируемые и фактические траты.
              </S.P1>
            </S.TextRow>

            <S.TextRow>
              <ul className="l-ul">
                <li>Переходите на закладку «Затраты»</li>
                <li>Нажмите кнопку «Добавить»</li>
                <li>В новом экране укажите название затраты. Например, «Аванс».</li>
                <li>Выберите “Добавить в смету”</li>
                <li>Выберите категорию, Например, «Услуги»</li>
                <li>Укажите количество. Например, 1</li>
                <li>Укажите единицу измерения — шт</li>
                <li>Укажите планируемую цену – 5000 рублей</li>
                <li>Укажите срок — завтра</li>
                <li>Нажмите кнопку «Добавить»</li>
                <li>Проверьте, что в ленте «Затрат» появилась затрата с названием «Аванс»</li>
                <li>Нажмите на затрату «Аванс»</li>
                <li>В открывшемся окне в поле чек/квитанция нажмите на иконку «+»</li>
                <li>Добавьте фото чека</li>
                <li>Нажмите кнопку «Карандаш»</li>
                <li>Нажмите кнопку «Оплачено»</li>
                <li>В поле «Оплачено по цене»  укажите фактическую стоимость услуги – 6000 руб</li>
                <li>Нажмите кнопку «Сохранить»</li>
                <li>Проверьте, что в затрате «Аванс» появились фактическая и планируемая цена, а также значок скрепки, что означает, что к данной затрате был добавлен чек.</li>
              </ul>
            </S.TextRow>


            <S.TextRow>
              <S.P1>
                Приложение позволяет работать со всеми затратами на едином экране. Нет необходимости переходить по разным задачам. Их можно найти, пролистав ленту экрана «Затраты». Также данный экран отображает общую сумму затраченных средств на данный момент и общий бюджет стройки, которая запланирована. Приложение считает их автоматически.
              </S.P1>
            </S.TextRow>


          </div>
        </div>
      </div>

      <div id="section-cb" className="text-2 text-center" style={{ marginTop: '164px' }}>Обратная связь</div>
      <div className="text-1 text-center" style={{ marginBottom: '44px' }}>Присылайте ваши вопросы и пожелания.</div>

      <LandingFeedbackReduxForm onSubmit={onFeedbackSubmit} />
      <LandingFooter />
    </S.Container>
  );
}

export default InstallAndroid;
