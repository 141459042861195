import Icon from 'icons';
import React from 'react';
import cn from 'classnames';
import { Carousel } from 'react-responsive-carousel';

import * as S from "./styles";

type Props = {
  section: any
}
const LandingSection = (props: Props) => {
  const { section } = props;

  if (section.type === 'cols') {
    const isNormalOrder = section.side === 'normal';
    const partLeftCN = cn('col-lg-7 order-1', {
      'order-md-2': isNormalOrder,
      'order-md-1': !isNormalOrder
    });
    const partRightCN = cn('col-lg-5 order-2', {
      'order-md-2': !isNormalOrder,
      'order-md-1': isNormalOrder
    });
    const media = section.images.map((relativePath: string) => ({
      source: `${process.env.PUBLIC_URL}/assets/img/${relativePath}`
    }));

    return (
      <div id={`section-${section.id}`} key={section.id} className="con-section">
        <div className="container">
          <div className="row">
            <div className={partLeftCN}>
              <div className="num" style={{ marginTop: '70px' }}>{section.num}</div>
              <div className="sec-title">{section.title}</div>
              <div className="sec-text">
                {section.text}
              </div>
            </div>
            <div className={partRightCN}>
              <div className="pr-media">
                <Carousel
                  stopOnHover={false}
                  interval={7000}
                  infiniteLoop={true}
                  autoPlay={false}
                  showArrows={false}
                  showStatus={false}
                  showThumbs={false}
                  dynamicHeight={false}>
                  {media.map((m: any) => {
                    return (
                      <S.CarouselImgContainer>
                        <S.CarouselImg src={m.source} />
                      </S.CarouselImgContainer>
                    )
                  })}
                </Carousel>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div id={`section-${section.id}`} key={section.id} className="con-section">
      <div className="container">
        <div className="row">
          {section.items?.map((it: any, index: number) => {
            const isLast = index === section.items?.length - 1;
            const className = isLast ? 'pr mb-0' : 'pr';
            return (
              <div key={it.icon} className="col-lg-4">
                <div className={className}>
                  <div className="pr-icon">
                    <Icon name={it.icon} />
                  </div>
                  <div className="pr-text" dangerouslySetInnerHTML={{ __html: it.text }}></div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
};

export default LandingSection;
