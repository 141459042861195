import UDCheckbox from 'app/modules/ud-forms/checkbox';
import { scrollToHref } from 'app/modules/ud-ui/scroll-to-href';
import React from 'react';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';


const LandingComponentsInstallForm = (props: any) => {
  const { 
    handleSubmit, /* step1, step2,*/ step3, step4, step5, step6, step7, step8, step9
  } = props;

  const onChange = (isChecked: boolean, href: string) => {
    if (isChecked) {
      scrollToHref(href)
    }
  };

  const onItemClicked = (href: string) => {
    scrollToHref(href)
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-4">
        <Field component={UDCheckbox}
          onClick={() => onItemClicked('#install_reg')}
          onChange={(v: any) => onChange(v, '#install_reg')}
          disabled={step3 != null}
          label="Регистрация аккаунта"
          name={"step_3"} />
      </div>
      <div className="mb-4">
        <Field component={UDCheckbox}
          onClick={() => onItemClicked('#install_project')}
          onChange={(v: any) => onChange(v, '#install_project')}
          disabled={step4 != null}
          label="Создание проекта"
          name={"step_4"} />
      </div>
      <div className="mb-4">
        <Field component={UDCheckbox}
          onClick={() => onItemClicked('#install_overview')}
          onChange={(v: any) => onChange(v, '#install_overview')}
          disabled={step5 != null}
          label="Обзор основных экранов"
          name={"step_5"} />
      </div>
      <div className="mb-4">
       <Field component={UDCheckbox}
          onClick={() => onItemClicked('#install_tasks')}
          onChange={(v: any) => onChange(v, '#install_tasks')}
          disabled={step6 != null}
          label="Работа с задачами"
          name={"step_6"} />
      </div>  
      <div className="mb-4">
        <Field component={UDCheckbox}
          onClick={() => onItemClicked('#install_notes')}
          onChange={(v: any) => onChange(v, '#install_notes')}
          disabled={step7 != null}
          label="Работа с заметками"
          name={"step_7"} />
      </div>
      <div className="mb-4">
        <Field component={UDCheckbox}
          onClick={() => onItemClicked('#install_docs')}
          onChange={(v: any) => onChange(v, '#install_docs')}
          disabled={step8 != null}
          label="Работа с документами"
          name={"step_8"} />
      </div>
      <div className="mb-4">
      <Field component={UDCheckbox}
        onClick={() => onItemClicked('#install_cost')}
        onChange={(v: any) => onChange(v, '#install_cost')}
        disabled={step9 != null}
        label="Работа с затратами"
        name={"step_9"} />
      </div>
    </form>
  );
}

let LandingComponentsInstallReduxForm: any = reduxForm<any>({ 
  form: 'landing_install',
  onChange: (values) => {
    localStorage.setItem('landing_install', JSON.stringify(values));
  },
  initialValues: JSON.parse(localStorage.getItem('landing_install'))
})(LandingComponentsInstallForm);

const formSelector = formValueSelector('landing_install');
LandingComponentsInstallReduxForm = connect((state: any) => {
  //const step1 = formSelector(state, 'step_1');
  //const step2 = formSelector(state, 'step_2');
  const step3 = formSelector(state, 'step_3');
  const step4 = formSelector(state, 'step_4');
  const step5 = formSelector(state, 'step_5');
  const step6 = formSelector(state, 'step_6');
  const step7 = formSelector(state, 'step_7');
  const step8 = formSelector(state, 'step_8');
  const step9 = formSelector(state, 'step_9');
  return {
   /* step1, step2, */ step3, step4, step5, step6, step7, step8, step9
  };
})(LandingComponentsInstallReduxForm);

export default LandingComponentsInstallReduxForm;
