import { createAsyncThunk } from "@reduxjs/toolkit";
import feedbackRepository from "app/modules/feedbacks/domain/repositories/FeedbackRepository"
import { toast } from "react-toastify";
import { reset } from "redux-form";

const PREFIX = "landing/feedback";

export const sendFeedback = createAsyncThunk<any, any>(
  `${PREFIX}/sendFeedback`,
  async (feedback: any, thunkApi) => {
    console.log('actions.ts --->  ---> 11: ', feedback)
    return feedbackRepository.create(feedback)
      .then((response) => {
        toast("Thank you! Message sent.", { type: toast.TYPE.SUCCESS, autoClose: 3000 });
        thunkApi.dispatch(reset('landing_feedback')); 
        return response;
      });
  }
)