import { combineReducers, configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { reducer as formReducer } from 'redux-form';

import landingReducer from "app/modules/landing/store";

export const rootReducer = combineReducers({
  form: formReducer,
  landing: landingReducer,
});

const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== 'production',
  // allow mutations for redux form images inputs
  middleware: [...getDefaultMiddleware({ immutableCheck: false })],
});

export type RootState = ReturnType<typeof rootReducer>;

export default store;

export type CustomDispatch = typeof store["dispatch"]