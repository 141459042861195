import React from 'react';
import { Switch, Route, NavLink, Redirect } from 'react-router-dom'
import styled from 'styled-components'

import * as S from "./styles";
import LandingLinksHeader from '../../components/links-header';

import Android from './android'
import Ios from './ios'

import and from '../../../../../assets/android.svg'
import ios from '../../../../../assets/ios.svg'

const ToggleContainer = styled.div`
  display: flex;
  position: fixed;
  @media (max-width: 992px) {
    position: relative;
  }
  @media (max-width: 768px) {
    justify-content: center;
  }
`

const Toggle = styled(NavLink)`
  padding: 16px;
  border: 1px solid #aaa;
  width: 120px;
  display: flex;
  align-items: center;
  text-decoration:none;
  color: #aaa;
  
  img {
    width: 1.2rem;
    margin-right: 0.5rem;
    opacity: 0.33;
  }
  
  &.active {
    background-color: #f7f7f7;
    border-color: #333;
    color: #333;
    box-shadow: 1px 2px 1px 2px rgba(0, 0, 0, 0.1);
    img {
      opacity: 0.9;
    }
  }
`

const Left = styled(Toggle)`
  border-radius: 20px 0 0 20px;
`

const Right = styled(Toggle)`
  border-radius:  0 20px 20px 0;
`

const LandingPagesInstallNew = () => {
  const onTryClicked = () => {
    window.open('https://client.progresto.app/')
  }

  return (
    <S.Container>
      <LandingLinksHeader onTryClicked={onTryClicked} />

      <div className="container">
        <S.Row className='row'>
          <div className='col-lg-3 col-xs-12'> </div>
          <ToggleContainer className='col-lg-3 col-xs-12'>
            <Left to='/install/ios'><img src={ios} alt='' />iOS</Left>
            <Right to='/install/android'><img src={and} alt='' />Android</Right>
          </ToggleContainer>
          <div className='col-lg-9'>
            <S.H2New noTopMargin id="install_reg" className="mt-4 mt-lg-0">Как пользоваться приложением</S.H2New>
          </div>
        </S.Row>
        <Switch>
          <Route path='/install/ios' component={Ios} />
          <Route path='/install/android' component={Android} />
          <Redirect to='/install/ios' />
        </Switch>
      </div>
    </S.Container>
  );
}

export default LandingPagesInstallNew;
