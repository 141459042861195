import styled from 'styled-components';

export const CarouselImgContainer = styled.div`
    padding: 50px;
    height: 100%;
    width: 100%;
`;

export const CarouselImg = styled.img`
    box-shadow: 0px 0px 40px 0px rgba(37, 34, 74, 0.12);
    border-radius: 32px;
`;